import {BREAKPOINT, MAX_WIDTH_PX} from 'aac-components/utils/styles';
import Button from 'aac-components/components/Button';
import styled from 'styled-components';
import PersonaAssessment from '../PersonaAssessment';

const StorySection = () => {
    return (
        <section className="story-section">
            <div className="story-section__inner">
                <div className="story-section__left">
                    <h2>Our Story and Mission</h2>
                    <p>
                        Since 2007, American Addiction Centers offers the largest network
                        of rehab facilities nationwide and a success rate that’s double
                        the national average.
                    </p>
                    <ul>
                        <li>
                            We treat drug and alcohol addiction along with mental and
                            behavioral health issues.
                        </li>
                        <li>
                            Our staff comprises a host of professionals, including
                            physicians, registered nurses, licensed marriage and family
                            therapists, psychologists, and psychiatrists.
                        </li>
                        <li>
                            Low patient-to-staff ratios ensure patients receive
                            personalized care.
                        </li>
                    </ul>
                    <div className="story-section__button">
                        <Button
                            style={{minWidth: '100%', fontWeight: 'normal'}}
                            theme="invert"
                            href="/media">
                            See Updates from AAC
                        </Button>
                    </div>
                </div>
                <div className="story-section__right">
                    <PersonaAssessmentStyles>
                        <PersonaAssessment />
                    </PersonaAssessmentStyles>
                </div>
            </div>
            <style jsx>
                {`
                    .story-section {
                        background: linear-gradient(
                            183.83deg,
                            rgba(248, 238, 229, 0.4) -29.92%,
                            rgba(229, 235, 245, 0.4) 137.15%
                        );
                    }
                    .story-section__inner {
                        max-width: ${MAX_WIDTH_PX};
                        margin: 0 auto;
                        padding: 64px 15px;
                        display: grid;
                        grid-template-columns: 1fr;
                        grid-gap: 40px;
                    }
                    @media screen and (min-width: ${BREAKPOINT}) {
                        .story-section__inner {
                            padding: 120px 0;
                            grid-template-columns: 700px auto;
                            grid-gap: 80px;
                            align-items: center;
                        }
                    }
                    .story-section__left li {
                        margin-bottom: 24px;
                    }
                    .story-section__right {
                        position: relative;
                        width: 100%;
                        border-radius: 8px;
                        overflow: hidden;
                        box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.15),
                            0px 1px 2px rgba(0, 0, 0, 0.3);
                    }
                    .story-section__button {
                        max-width: 100%;
                    }
                    @media screen and (min-width: ${BREAKPOINT}) {
                        .story-section__button {
                            max-width: fit-content;
                        }
                    }
                `}
            </style>
        </section>
    );
};
export default StorySection;

const PersonaAssessmentStyles = styled.div`
    .container {
        padding: 40px 40px 60px 40px !important;
    }
    .options {
        max-height: 120px;
    }
`;
